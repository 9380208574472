<template>
  <div>
    <!-- 头部 -->
    <div
      class="page-header"
      :style="{ borderBottom: showBottom == true ? '1px solid #ccc' : 'none' }"
    >
      <el-row>
        <el-col :span="12">
          <div class="navbar-header">
            <router-link to="/index">
              <img src="../assets/imgs/logo.png" />
            </router-link>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="bug hidden-sm-and-up">
            <a @click="showMenu">
              <el-icon class="menu"><Menu /></el-icon>
            </a>
          </div>
          <div class="header-right hidden-xs-only">
            <ul>
              <router-link to="/index">
                <li @click="changeActive(1)">
                  <a :class="{ active: isActive == 1 }" href="#">产品</a>
                </li>
              </router-link>

              <router-link to="/case">
                <li @click="changeActive(2)">
                  <a :class="{ active: isActive == 2 }" href="#">案例</a>
                </li>
              </router-link>

              <router-link to="/pricing">
                <li @click="changeActive(3)">
                  <a :class="{ active: isActive == 3 }" href="#">咨询与购买</a>
                </li>
              </router-link>
              <li @click="toSuggestion">
                <span>意见与反馈</span>
              </li>
              <!-- <li @click="toOldVersion">
                <span>前往旧版</span>
              </li> -->
              <li>
                <div id="user" v-if="isLogin">
                  <router-link to="/home">
                    <span class="into">
                      进入系统
                      <img src="../assets/imgs/you.png" alt="" />
                    </span>
                  </router-link>
                  <img class="person" src="../assets/imgs/people.png" alt="" />
                  <a id="userName">{{ userName }}</a>
                </div>
                <div v-else>
                  <li>
                    <span @click="goRegister" class="register">注册</span>
                  </li>
                  <li>
                    <el-button type="primary" class="login" @click="goLogin">
                      登录
                    </el-button>
                  </li>
                </div>
              </li>
            </ul>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="menuBox" v-show="menuBox">
      <ul>
        <router-link to="/index">
          <li @click="changeActive(1)">
            <a :class="{ active: isActive == 1 }" href="#">产品</a>
          </li>
        </router-link>

        <router-link to="/case">
          <li @click="changeActive(2)">
            <a :class="{ active: isActive == 2 }" href="#">案例</a>
          </li>
        </router-link>

        <router-link to="/pricing">
          <li @click="changeActive(3)">
            <a :class="{ active: isActive == 3 }" href="#">咨询与购买</a>
          </li>
        </router-link>
      </ul>
    </div>

    <!-- 背景图 -->
    <div class="bannerImg" id="nav">
      <div class="center">
        <h1 class="slogan">文本大数据分析与挖掘云平台</h1>
        <h4 class="slogan_en">Text Analysis and Mining Cloud Platform</h4>
        <p class="desc">
          应用自然语言处理、机器学习、人工智能、大数据等技术对大规模文本数据进行分析挖掘，并呈现可视化分析结果
        </p>
        <p class="desc">
          Analyzing and mining large scale text data with various technolgies
          such as NLP, Machine Learning, Artifical Intelligence and Bigdata.
        </p>
      </div>
    </div>

    <!-- icon区域 -->
    <div class="icon-box">
      <div class="icon-banner">
        <el-row class="bgc">
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="function-grid">
              <div class="fun-card">
                <div class="bg-circle" @click="goSpider('#spider1')">
                  <img src="../assets/imgs/clear.png" />
                </div>
                <h4 class="function-name">数据清洗</h4>
              </div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="function-grid">
              <div class="fun-card">
                <div class="bg-circle" @click="goSpider('#spider2')">
                  <img src="../assets/imgs/NLP.png" />
                </div>
                <h4 class="function-name">锐研NLP</h4>
              </div>
            </div>
          </el-col>
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="fun-card">
              <div class="bg-circle" @click="goSpider('#spider3')">
                <img src="../assets/imgs/frequency.png" />
              </div>
              <h4 class="function-name">词频统计</h4>
            </div>
          </el-col>
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="fun-card">
              <div class="bg-circle" @click="goSpider('#spider4')">
                <img src="../assets/imgs/classify.png" />
              </div>
              <h4 class="function-name">文本分类</h4>
            </div>
          </el-col>
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="fun-card">
              <div class="bg-circle" @click="goSpider('#spider5')">
                <img src="../assets/imgs/clustering.png" />
              </div>
              <h4 class="function-name">文本聚类</h4>
            </div>
          </el-col>
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="fun-card">
              <div class="bg-circle" @click="goSpider('#spider6')">
                <img src="../assets/imgs/emotion.png" />
              </div>
              <h4 class="function-name">情感分析</h4>
            </div>
          </el-col>
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="fun-card">
              <div class="bg-circle" @click="goSpider('#spider7')">
                <img src="../assets/imgs/word-xl.png" />
              </div>
              <h4 class="function-name">词向量分析</h4>
            </div>
          </el-col>
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="fun-card">
              <div class="bg-circle" @click="goSpider('#spider8')">
                <img src="../assets/imgs/theme.png" />
              </div>
              <h4 class="function-name">主题分析</h4>
            </div>
          </el-col>
          <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
            <div class="fun-card">
              <div class="bg-circle" @click="goSpider('#spider9')">
                <img src="../assets/imgs/word-gx.png" />
              </div>
              <h4 class="function-name">词共现分析</h4>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 主体内容区域 -->
    <div class="nav">
      <div class="y-main" v-if="isMain">
        <div class="icon-banner">
          <el-row id="spider1" class="bgf container">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-clear.png" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">数据清洗</h4>
                <ul class="content-item">
                  <li>对文本库进行数据预处理的可视化工具；</li>
                  <li>创建不同的清洗条件，根据设置的条件能快速清洗数据；</li>
                  <li>对清洗的数据可以保存到新的文本库。</li>
                </ul>
              </div>
            </el-col>
          </el-row>
          <el-row id="spider2" class="bgf container">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">锐研NLP</h4>
                <ul class="content-item">
                  <li>集成主流自然语言处理（NLP）引擎；</li>
                  <li>
                    支持文本分词，词性标注，文本摘要，关键词提取和词云展现；
                  </li>
                  <li>支持自定义文本语料库。</li>
                </ul>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-NLP.png" alt="" />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="bgc container" id="spider3">
          <el-row class="icon-banner">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-frequency.png" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">词频统计</h4>
                <ul class="content-item">
                  <li>支持词频统计，以及词云图分析展现；</li>
                  <li>可根据不同的词性进行筛选统计；</li>
                  <li>可根据条件筛选对部分文本进行词频统计。</li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="bgc container" id="spider4">
          <el-row class="icon-banner">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">文本分类</h4>
                <ul class="content-item">
                  <li>支持多种分类算法；</li>
                  <li>支持对分类训练集的管理和维护；</li>
                  <li>支持对多种分类结果进行比较。</li>
                </ul>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-classify.png" alt="" />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div id="spider5" class="bgf container">
          <el-row class="icon-banner">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-clustering.png" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">文本聚类</h4>
                <ul class="content-item">
                  <li>支持K-Means, STC, Lingo等多种聚类算法；</li>
                  <li>支持聚类算法参数的调整；</li>
                  <li>聚类结果可以导出；</li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
        <div id="spider6" class="bgf container">
          <el-row class="icon-banner">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">情感分析</h4>
                <ul class="content-item">
                  <li>支持进行基于情感字典的情感分析；</li>
                  <li>集成多个情感字典库，包括台湾大学中文情感库、LIWC等</li>
                  <li>支持自定义情感字典库。</li>
                </ul>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-emotion.png" alt="" />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="bgc container" id="spider7">
          <el-row class="icon-banner">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-word-xl.png" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">词向量分析</h4>
                <ul class="content-item">
                  <li>对一组文本进行Word2Vec词向量分析；</li>
                  <li>可查询词的词向量；</li>
                  <li>可以发现词的相似词。</li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="bgc container" id="spider8">
          <el-row class="icon-banner">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">主题分析</h4>
                <ul class="content-item">
                  <li>基于LDA算法对一组文本进行主题分析；</li>
                  <li>可以设定LDA算法的模型参数；</li>
                  <li>可以查询算法运行的主题分类结果。</li>
                </ul>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-theme.png" alt="" />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div id="spider9" class="bgf container">
          <el-row class="icon-banner">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">词共现分析</h4>
                <ul class="content-item">
                  <li>基于词共现网络算法对一组文本进行分析；</li>
                  <li>支持查询词和词共现的概率；</li>
                  <li>可视化呈现词共现网络图。</li>
                </ul>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/wtc.jpg" alt="" />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="y-main" v-else>
        <div class="icon-banner">
          <el-row id="spider11" class="bgf container">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-clear.png" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">数据清洗</h4>
                <ul class="content-item">
                  <li>对文本库进行数据预处理的可视化工具；</li>
                  <li>创建不同的清洗条件，根据设置的条件能快速清洗数据；</li>
                  <li>对清洗的数据可以保存到新的文本库。</li>
                </ul>
              </div>
            </el-col>
          </el-row>
          <el-row id="spider21" class="bgf container">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-NLP.png" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">锐研NLP</h4>
                <ul class="content-item">
                  <li>集成主流自然语言处理（NLP）引擎；</li>
                  <li>
                    支持文本分词，词性标注，文本摘要，关键词提取和词云展现；
                  </li>
                  <li>支持自定义文本语料库。</li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-row class="bgc container" id="spider31">
          <div class="icon-banner">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-frequency.png" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">词频统计</h4>
                <ul class="content-item">
                  <li>支持词频统计，以及词云图分析展现；</li>
                  <li>可根据不同的词性进行筛选统计；</li>
                  <li>可根据条件筛选对部分文本进行词频统计。</li>
                </ul>
              </div>
            </el-col>
          </div>
        </el-row>
        <el-row class="bgc container" id="spider41">
          <div class="icon-banner">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-classify.png" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">文本分类</h4>
                <ul class="content-item">
                  <li>支持多种分类算法；</li>
                  <li>支持对分类训练集的管理和维护；</li>
                  <li>支持对多种分类结果进行比较。</li>
                </ul>
              </div>
            </el-col>
          </div>
        </el-row>
        <el-row id="spider51" class="bgf container">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img src="../assets/imgs/module-clustering.png" alt="" />
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <h4 class="content-title">文本聚类</h4>
              <ul class="content-item">
                <li>支持K-Means, STC, Lingo等多种聚类算法；</li>
                <li>支持聚类算法参数的调整；</li>
                <li>聚类结果可以导出；</li>
              </ul>
            </div>
          </el-col>
        </el-row>
        <el-row id="spider61" class="bgf container">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img src="../assets/imgs/module-emotion.png" alt="" />
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <h4 class="content-title">情感分析</h4>
              <ul class="content-item">
                <li>支持进行基于情感字典的情感分析；</li>
                <li>集成多个情感字典库，包括台湾大学中文情感库、LIWC等</li>
                <li>支持自定义情感字典库。</li>
              </ul>
            </div>
          </el-col>
        </el-row>
        <el-row class="bgc container" id="spider71">
          <div class="icon-banner">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-word-xl.png" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">词向量分析</h4>
                <ul class="content-item">
                  <li>对一组文本进行Word2Vec词向量分析；</li>
                  <li>可查询词的词向量；</li>
                  <li>可以发现词的相似词。</li>
                </ul>
              </div>
            </el-col>
          </div>
        </el-row>
        <el-row class="bgc container" id="spider81">
          <div class="icon-banner">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content">
                <div class="main-img">
                  <img src="../assets/imgs/module-theme.png" alt="" />
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="grid-content main-text">
                <h4 class="content-title">主题分析</h4>
                <ul class="content-item">
                  <li>基于LDA算法对一组文本进行主题分析；</li>
                  <li>可以设定LDA算法的模型参数；</li>
                  <li>可以查询算法运行的主题分类结果。</li>
                </ul>
              </div>
            </el-col>
          </div>
        </el-row>
        <el-row id="spider91" class="bgf container">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content">
              <div class="main-img">
                <img src="../assets/imgs/wtc.jpg" alt="" />
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="grid-content main-text">
              <h4 class="content-title">词共现分析</h4>
              <ul class="content-item">
                <li>基于词共现网络算法对一组文本进行分析；</li>
                <li>支持查询词和词共现的概率；</li>
                <li>可视化呈现词共现网络图。</li>
              </ul>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 客户类型 -->
      <div class="clinet">
        <div class="header">
          <h1 class="title">客户类型</h1>
        </div>

        <div class="client-nav icon-banner">
          <el-row>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="client-grid">
                <div class="icon-card">
                  <img
                    class="img-icon"
                    src="../assets/imgs/client-enterprise.png"
                  />
                </div>
                <h4 class="client-type">企业客户</h4>
                <ul class="client-list">
                  <li>企业舆情分析</li>
                  <li>竞争情报分析</li>
                  <li>招投标监测</li>
                  <li>价格指数监测</li>
                  <li>产品评价分析</li>
                </ul>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="client-grid">
                <div class="icon-card">
                  <img
                    class="img-icon"
                    src="../assets/imgs/client-investigate.png"
                  />
                </div>
                <h4 class="client-type">市场调研机构</h4>
                <ul class="client-list">
                  <li>市场调查与分析</li>
                  <li>产品研究</li>
                  <li>用户行为研究</li>
                  <li>行业研究</li>
                  <li>决策咨询</li>
                </ul>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="client-grid">
                <div class="icon-card">
                  <img
                    class="img-icon"
                    src="../assets/imgs/client-education.png"
                  />
                </div>
                <h4 class="client-type">科研教育机构</h4>
                <ul class="client-list padding-left70">
                  <li>大数据课程培训</li>
                  <li>科研分析</li>
                  <li>新闻舆情分析</li>
                  <li>网络心态研究</li>
                  <li>社会热点研究</li>
                </ul>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="client-grid">
                <div class="icon-card">
                  <img
                    class="img-icon"
                    src="../assets/imgs/client-government.png"
                  />
                </div>
                <h4 class="client-type">政府机关</h4>
                <ul class="client-list">
                  <li>社情民意调查</li>
                  <li>政府舆情分析</li>
                  <li>企业舆情分析</li>
                  <li>海外舆情分析</li>
                  <li>新闻大数据平台</li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- 使用锐研 -->
      <div class="icon-banner use">
        <div class="use-header">
          <h1 class="title">他们正在使用锐研</h1>
        </div>
        <div class="use-body">
          <div class="logo-grids" v-if="isLogo">
            <div class="logo">
              <el-row>
                <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img src="../assets/imgs/logo1.png" title="南京大学" />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img src="../assets/imgs/logo2.png" title="复旦大学" />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img src="../assets/imgs/logo3.png" title="四川大学" />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img
                        src="../assets/imgs/logo4.png"
                        title="华南理工大学"
                      />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img src="../assets/imgs/logo5.png" title="同济大学" />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img
                        src="../assets/imgs/logo6.png"
                        title="华东师范大学"
                      />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img
                        src="../assets/imgs/logo7.png"
                        title="四川外国语大学"
                      />
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="logo-bottom">
              <el-row>
                <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img
                        src="../assets/imgs/logo8.png"
                        title="北京师范大学"
                      />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img src="../assets/imgs/logo11.png" title="江苏省电台" />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img
                        src="../assets/imgs/logo12.png"
                        title="上海市公安局"
                      />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img src="../assets/imgs/logo13.png" title="腾讯网" />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img src="../assets/imgs/logo9.png" title="河海大学" />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img
                        src="../assets/imgs/logo10.png"
                        title="紫金传媒智库"
                      />
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="logo-grids" v-else>
            <div class="logo">
              <el-row>
                <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img src="../assets/imgs/logo1.png" title="南京大学" />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img src="../assets/imgs/logo2.png" title="复旦大学" />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img src="../assets/imgs/logo3.png" title="四川大学" />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img
                        src="../assets/imgs/logo4.png"
                        title="华南理工大学"
                      />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img src="../assets/imgs/logo5.png" title="同济大学" />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img
                        src="../assets/imgs/logo6.png"
                        title="华东师范大学"
                      />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="3" :xl="3">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img
                        src="../assets/imgs/logo7.png"
                        title="四川外国语大学"
                      />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img
                        src="../assets/imgs/logo8.png"
                        title="北京师范大学"
                      />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img src="../assets/imgs/logo11.png" title="江苏省电台" />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img
                        src="../assets/imgs/logo12.png"
                        title="上海市公安局"
                      />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img src="../assets/imgs/logo13.png" title="腾讯网" />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img src="../assets/imgs/logo9.png" title="河海大学" />
                    </div>
                  </div>
                </el-col>
                <el-col :xs="6" :sm="6" :lg="4" :xl="4">
                  <div class="logo-grid">
                    <div class="logo-card">
                      <img
                        src="../assets/imgs/logo10.png"
                        title="紫金传媒智库"
                      />
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="section-bottom">
          <router-link to="/case">
            <a href="#">案例</a>
            <i class="el-icon-arrow-right"></i>
          </router-link>
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <div class="footer">
      <!--      <div class="footer-top">-->
      <!--        <div class="social-icons">-->
      <!--          <a class="icon" href="mailto:ruiyan@wellsurvey.cn" target="_blank">-->
      <!--            <img src="../assets/imgs/xinXi.png" alt="">-->
      <!--          </a>-->
      <!--          <a class="icon" href="https://weibo.com/p/1006065849234540/home" target="_blank">-->
      <!--            <img src="../assets/imgs/weiBo.png" alt="">-->
      <!--          </a>-->
      <!--          <a class="icon mr" href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzA4Nzk1MjIzNw==&scene=123#wechat_redirect" target="_blank">-->
      <!--            <img src="../assets/imgs/weiXin.png" alt="">-->
      <!--          </a>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="footer-middle">
        <div class="bottom-menu">
          <span>
            锐研•云文析 互联网大数据研究云平台
            <a class="icon" href="mailto:ruiyan@wellsurvey.cn" target="_blank">
              mailto:ruiyan@wellsurvey.cn
            </a>
          </span>
        </div>
      </div>
      <div class="footer-bottom">
        <p>
          Copyright &copy;
          <a
            href="http://www.monetware.com"
            target="_blank"
            title="数据研究技术服务商"
          >
            Monetware
          </a>
          Inc. All rights reserved.
          <a href=" http://beian.miit.gov.cn" target="_blank">
            沪ICP备09099644号-17
          </a>
        </p>
      </div>
    </div>
    <el-dialog title="跳转提示" v-model="dialogVisible" width="30%">
      <span>
        2022年1月27日及此后的所有旧版数据都将不会和新版进行同步。是否进行跳转？
      </span>
      <!-- <span slot="footer" class="dialog-footer"> -->
      <template v-slot:footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmToOldVersion">
            确 定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import { httpPost, httpPostIsAnonymous } from "../api/httpService";
import { goLogin, goRegister } from "@/utils/login";

export default {
  data() {
    return {
      isActive: 1, // 切换导航
      isLogin: false, // 用户是否登录
      showBottom: false,
      userName: "",
      menuBox: false,
      isMain: true,
      screenWidth: "",
      isLogo: true,
      dialogVisible: false
    };
  },

  created() {
    // /*判断用户是否登录*/
    setTimeout(() => {
      // console.log(
      //   'VueCookies.get("tokenWeb")',
      //   VueCookies.get("tokenWeb"),
      //   VueCookies.get("userInfo")
      // );
      if (VueCookies.get("tokenWeb") && VueCookies.get("userInfo")) {
        let userInfo = VueCookies.get("userInfo");
        this.userName = userInfo.name;
        httpPost("/auth/role", null).then((res) => {
          // console.log("index");
          if (res && res.code === 0) {
            userInfo.nlpPermission = res.data.role;
            userInfo.ifLoginNlp = true;
            // VueCookies.set("userInfo", JSON.stringify(userInfo));
            // VueCookies.set(
            //   "userInfo",
            //   JSON.stringify(userInfo),
            //   null,
            //   "/",
            //   "ringdata.com",
            //   false
            // );
          }
        });
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
    }, 100);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听（绑定）滚轮滚动事件
    this.screenWidth = document.body.clientWidth;
    if (this.screenWidth <= 768) {
      this.isMain = false;
      this.isLogo = false;
    } else {
      this.isMain = true;
      this.isLogo = true;
    }
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
        if (this.screenWidth <= 768) {
          this.isMain = false;
          this.isLogo = false;
        } else {
          this.isMain = true;
          this.isLogo = true;
        }
      })();
    };
    this.addViewTimes();
  },

  methods: {
    goLogin: goLogin,
    goRegister: goRegister,
    goToRegister() {
      window.location.href =
        "https://www.ringdata.com/register?rp=https://wx.ringdata.com/login";
    },
    toSuggestion() {
      window.open(
        "https://www.ringdata.com/feedback?productCategory=2&productType=5"
      );
    },
    toOldVersion() {
      // console.log("???");
      this.dialogVisible = true;
      // window.open("https://wx.ringdata.com")
    },
    confirmToOldVersion() {
      this.dialogVisible = false;
      window.open("https://wx.ringdata.com/old/");
    },

    // 切换导航
    changeActive(current) {
      if (current != 1) {
        this.destroyed();
      }
      this.isActive = current;
    },

    // 锚点跳转
    goSpider(id) {
      if (this.isMain == true) {
        this.$el.querySelector(id).scrollIntoView(true);
      } else {
        this.$el.querySelector(id + "1").scrollIntoView(true);
      }
    },

    // 监听滚轮事件
    handleScroll: function () {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.scroll > 0) {
        this.showBottom = true;
      } else {
        this.showBottom = false;
      }
    },

    // 销毁滚轮事件
    destroyed: function () {
      window.removeEventListener("scroll", this.handleScroll); //  离开页面清除（移除）滚轮滚动事件
    },

    // 展开菜单
    showMenu() {
      this.menuBox = !this.menuBox;
    },

    // 增加访问次数
    addViewTimes() {
      let key = "VIEW_NLP";
      httpPostIsAnonymous("/show/update/view/" + key, null).then((res) => {
        if (res && res.code === 0) {
          // console.log("")
        }
      });
    }
  }
};
</script>

<style scoped>
.header-right >>> .el-button {
  padding: 8px 13px;
  margin-left: 30px;
  margin-bottom: 3px;
}
</style>
<style lang="scss" scoped>
.mr {
  margin-right: 0 !important;
}
.icon-banner {
  max-width: 1200px;
  margin: 0 auto;
}
.people {
  width: 15px;
  height: 17px;
  vertical-align: middle;
  padding-bottom: 2px;
}

#user {
  line-height: 0;
  .person {
    display: inline-block;
    width: 18px;
    vertical-align: middle;
    margin-bottom: 4px;
    margin-right: 3px;
  }
}
.bug {
  cursor: pointer;
  width: 35px;
  height: 60px;
  float: right;
  padding-right: 20px;
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
  a {
    cursor: pointer;
    width: 35px;
    height: 60px;
    display: inline-block;
    -webkit-tap-highlight-color: transparent;
  }
}
.menu {
  font-size: 28px;
  color: #666;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  line-height: 60px;
  position: relative;
  top: 15px;
}
.menuBox {
  z-index: 1000;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  text-align: center;
  line-height: 50px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  li {
    cursor: pointer;
    z-index: 1000;
    a {
      &:hover {
        color: #e4494c;
      }
      &.active {
        color: #e4494c;
      }
    }
  }
  .menu-setting {
    border-top: 1px solid #ccc;
    padding-top: 15px;
    .register {
      width: 70px;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      margin-right: 40px;
    }
  }
}
.into {
  margin-left: 30px;
  margin-right: 10px;
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 13px;
  img {
    display: inline-block;
    width: 20px;
    vertical-align: middle;
    margin-bottom: 4px;
  }
}
a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  color: #444;
}
button,
button:focus {
  outline: none;
}
ul,
ol {
  list-style: none;
  padding: 0;
}

// 头部
.page-header {
  position: fixed;
  left: 0;
  top: 0;
  height: 60px;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  z-index: 10;
  .navbar-header {
    width: 20%;
    height: 60px;
    line-height: 60px;
    float: left;
    padding: 0px 20px 0px;
    img {
      display: inline-block;
      width: 150px;
      height: 44px;
      vertical-align: middle;
    }
  }
  .header-right {
    float: right;
    text-align: right;
    ul {
      width: 100%;
      height: 60px;
      line-height: 60px;
      overflow: hidden;
      li {
        margin-right: 20px;
        float: left;
        cursor: pointer;
        a {
          &.active {
            color: #e94743;
          }
        }
        .register {
          font-size: 15px;
          font-weight: 500;
          color: #000;
          margin-left: 20px;
        }
        i {
          color: #606266;
        }
        #userImg {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 5px;
          margin-left: 10px;
        }
        #userName {
          margin-left: 2px;
          display: inline-block;
          padding: 0;
          line-height: 60px;
          font-size: 14px;
          color: #777;
        }
        #userName:hover {
          color: #777;
        }
      }
    }
  }
}

// 背景图
.bannerImg {
  width: 100%;
  color: #fff;
  text-align: center;
  background: url("../assets/imgs/bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 790px;
  .center {
    padding: 200px 0;
    .slogan {
      text-align: center;
      font-size: 40px;
      font-weight: 300;
      color: #fff;
      margin-top: 60px;
      padding: 30px 15px 10px;
    }
    .slogan_en {
      text-align: center;
      color: #fff;
      font-size: 25px;
      font-weight: 300;
      padding: 0 15px 30px;
    }
    .desc {
      font-size: 20px;
      font-weight: 300;
      text-align: center;
      color: #fff;
      max-width: 768px;
      margin: 0 auto;
      margin-bottom: 5px;
      padding: 0 15px;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
    }
  }
}

// ICON
.icon-box {
  background-color: #f7f8fd;
  padding: 50px 40px;
  .bg-circle {
    width: 120px;
    height: 120px;
    text-align: center;
    line-height: 120px;
    background-color: #d2d3d7;
    margin: 0 auto;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    &:hover {
      background-color: #e4494c;
      -webkit-transition: ease-in 0.5;
      -moz-transition: ease-in 0.5;
      -ms-transition: ease-in 0.5;
      -o-transition: ease-in 0.5;
      transition: ease-in 0.5;
    }
    img {
      margin-top: 27px;
    }
  }
  .function-name {
    font-size: 18px;
    text-align: center;
    padding: 30px 0px 15px;
    font-weight: 300;
  }
}

// 主体内容区域
.y-main {
  .bgc {
    background-color: #f7f8fd;
  }
  .bgf {
    background-color: #fff;
  }
  .main-img {
    padding: 20px 40px;
    img {
      display: block;
      width: 100%;
      margin: 0 auto;
    }
  }
  .main-text {
    padding: 10px 40px;
    .content-title {
      font-size: 25px;
      color: #495766;
      font-weight: 300;
      margin: 20px 0px 30px;
    }
    .content-item {
      list-style: disc;
      color: #666;
      padding-left: 20px;
      li {
        margin: 10px 0px;
      }
    }
  }
}

// 客户类型
.clinet {
  padding: 0px 0px 50px;
  .header {
    .title {
      text-align: center;
      font-size: 28px;
      font-weight: 300;
      color: #495766;
      margin: 20px 0px 30px;
    }
  }
  .client-nav {
    overflow: hidden;
  }
  .client-grid {
    padding-left: 20px;
    width: 100%;
    float: left;
    .icon-card {
      width: 80px;
      margin: 0 auto;
      .img-icon {
        width: 70px;
        height: 70px;
      }
    }
    .client-type {
      font-size: 18px;
      font-weight: 300;
      color: #444;
      text-align: center;
      padding: 15px 0px 0px;
    }
    .client-list {
      list-style: disc;
      color: #666;
      padding-left: 0px;
      li {
        margin: 10px 0px;
        text-align: center;
        color: #666;
        font-weight: 300;
      }
    }
  }
}

// 使用锐研
.use {
  .use-header {
    .title {
      text-align: center;
      font-size: 30px;
      color: #666;
      font-weight: 300;
      margin-bottom: 20px;
    }
  }
  .use-body {
    overflow: hidden;
    .logo-grids {
      margin: 0 auto;
      height: auto;
      width: 100%;
      text-align: center;
      .logo-card {
        width: 100%;
      }
    }
    img {
      width: 100%;
    }
  }

  .logo-bottom {
    img {
      width: 100%;
    }
  }
  .section-bottom {
    overflow: hidden;
    margin: 40px 0;
    text-align: center;
    a {
      font-size: 16px;
      color: #e94743;
      font-weight: 500;
    }
    i {
      font-weight: bold;
    }
  }
}

// 底部
.footer {
  padding: 55px 10px;
  text-align: center;
  background: #eee;
  .social-icons img {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-top: 10px;
  }
  .social-icons a {
    width: 45px;
    height: 45px;
    line-height: 45px;
    display: inline-block;
    background: #bdbdbd;
    color: #fff;
    font-size: 20px;
    text-align: center;
    line-height: 45px;
    margin-right: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    &:hover {
      opacity: 0.8;
      filter: alpha(opacity=80);
      -webkit-filter: alpha(opacity=80);
      -moz-opacity: 0.8;
      -khtml-opacity: 0.8;
    }
  }
  .footer-middle {
    color: #a0a0a0;
    padding: 10px 0px;
    font-size: 12px;
  }
  .footer-bottom {
    font-size: 12px;
    p {
      color: #a0a0a0;
    }
    a {
      color: #337ab7;
    }
  }
}
</style>
