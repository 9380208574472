import router from "@/router";
/**前往登录、注册、找回密码 */
export const goLogin = () => {
  const clientType = "ANALYZER";
  if (process.env.NODE_ENV == "production") {
    // if (process.env.NODE_ENV == "production") {
    //线上
    const targetUrl = "https://wx.ringdata.com/home/";
    window.location.href =
      "https://www.ringdata.com/user-center/login/" +
      clientType +
      "/" +
      encodeURIComponent(targetUrl);
  } else {
    //开发
    // const targetUrl = "http://192.168.0.41:8081/project/";
    // window.location.href =
    //   "http://192.168.0.41:8080/login/" +
    //   clientType +
    //   "/" +
    //   encodeURIComponent(targetUrl);
    router.push("/login");
  }
};

export const goRegister = () => {
  const clientType = "ANALYZER";
  const targetUrl = "https://wx.ringdata.com/project/";
  window.location.href =
    "https://www.ringdata.com/user-center/register/" +
    clientType +
    "/" +
    encodeURIComponent(targetUrl);
};
